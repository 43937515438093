.big-buy-button {
  font-size: 200%;
  padding: 20px;
  background: var(--bg-color);
  border: 2px solid var(--text-color);
  width: 300px;
  margin-left: auto;
  margin-right: auto;
  display: block;

}
.big-buy-button:hover {
  cursor: pointer;
}
.input-field {
  width: 100%;
  margin-bottom: 10px;
}
.input-field input,
.input-field select,
.submit-form-button {
  padding: 10px;
  width: 100%;
  font-family: monospace;
  font-size: 1rem;
}
.input-field label {
  font-family: monospace;
  font-size: 1rem;
}
.form-error {
  font-family: monospace;
  color: var(--error-color);
  font-size: 1rem;
}
.address-table,
.price-table {
  font-family: monospace;
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
  font-size: 1rem;
}
.price-table td,
.address-table td {
  padding: 5px 0px;
}
.price-table td:last-child {
  text-align: right;
}
.control-link {
  color: #8191e6;
  text-decoration: underline;
  padding-left: 5px;
}
.control-link:hover {
  cursor: pointer;
}
#success {
  padding: 20px 10px;
  max-width: 600px;
  margin: 0px auto;
  color: #0a0a0a;
}
