.book-thumbs-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  column-gap: 10px;
  row-gap: 10px;
  background: var(--light-grey);
  padding: 10px;
}
.book-thumb {
  height: 125px;
  overflow: hidden;
  background: #FFF;
}
.book-thumb:hover {
  cursor: pointer;
  opacity: 0.5;
}
.thumb-overlay {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgb(0, 0, 0, 0.8);
  z-index: 100;
}
.thumb-overlay-inner {
  max-width: 1200px;
  padding: 20px 10px;
  height: 90%;
  margin: 2% auto;
}
.overlay-image-wrapper {
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 100%;
  height: 100%;
}
.thumb-nav {
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-size: 70px;
  margin-top: 20%;
}
.thumb-nav div:nth-child(1){
  text-align: left;
  padding: 20px;
  opacity: 0.2;
}
.thumb-nav div:nth-child(2){
  text-align: right;
  padding: 20px;
  opacity: 0.2;
}
.thumb-nav div:hover {
  cursor: pointer;
  opacity: 1;
}
.close-thumb-overlay {
  position: absolute;
  display: inline-block;
  padding: 10px;
  right: 0px;
  color: red;
  font-size: 70px;
  opacity: 0.2;
}
.close-thumb-overlay:hover {
  cursor: pointer;
  opacity: 1;
}
@media only screen and (max-width: 800px) {
  .book-thumbs-wrapper {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}
@media only screen and (max-width: 700px) {
  .book-thumbs-wrapper {
    grid-template-columns: 1fr 1fr 1fr ;
  }
}
@media only screen and (max-width: 600px) {
  .book-thumbs-wrapper {
    grid-template-columns: 1fr 1fr;
  }
}
