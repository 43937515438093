.vid-wrapper {
  padding-top: 65%;
  overflow: hidden;
  position: relative;
}
.vid-wrapper iframe {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  border: none;
}
.video-soon {
  font-size: 100px;
  text-align: center;
  padding: 200px 0px 0px 0px;
  position: absolute;
  top: 0;
  width: 100%;
}
#banner-vid {
  width: 100%;
  border: none;
}
.front-main {
  width: 100%;
  background: var(--bg-color);
}
.front-grid {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 40px 10px;
  max-width: 900px;
  margin: 0px auto;
}
.front-grid section {
  margin-bottom: 100px;
}
.front-mid-pane {
  position: relative;
  height: auto;
  width: 100%;
  padding: 10px;
  background: var(--bg-color);
}
.front-mid-inner {
  display: flex;
  flex-direction: row;
  margin-top: 100px;
  justify-content: space-between;
  max-width: 1200px;
  margin: 50px auto 0px auto;
  padding: 10px;
}
.squares-pane {
  max-width: 630px;
  margin: 0px auto;
}

.banner-img-wrap {
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
  width: 100%;
  z-index: -10;
}
.banner-img-wrap img {
  visibility: hidden;
  width: 100%;
}
.contact-pane {
  font-size: 2em;
  width: 750px;
  margin: 0px auto;
  text-align: center;
}
.front-image-pane {
  height: 600px;
  width: 100%;
  background-color: var(--bg-color);
  z-index: 10;
  position: relative;
}
.front-image {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
}
@media only screen and (max-width: 1200px) {
  .banner-img-wrap {
    background-attachment: inherit;
  }
  .contact-pane {
    width: 100%;
  }
  .squares-pane {
    width: 100%;
    margin: 0px auto;
  }
  .front-mid-inner {
    display: flex;
    flex-direction: column-reverse;
    margin-top: 20px;
    justify-content: space-between;
  }
  .front-mid-inner > div:nth-child(1) {
    flex: 1 1 50%;
  }
  .front-mid-inner > div:nth-child(2) {
    flex: 1 1 25%;
  }
}
@media only screen and (max-width: 800px) {

  .front-mid-pane {}
  .squares-pane img{
    width: 100%;
  }
  .squares-pane  {
    margin-left: auto;
    margin-right: auto;
    width: 250px;
  }
  .contact-pane {
    font-size: 1.2em;
  }
  .video-soon {
    font-size: 30px;
    padding: 10px 0px 0px 0px;
  }
}
